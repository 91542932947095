import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { useApplicationContext } from "contexts/app";

import { onRedirectAuthentication } from "utils/authentication-utils";

const useCheckIsUserAuthenticated = () => {
  const [loading, setLoading] = useState(true);

  const { session, configuration } = useApplicationContext();
  const router = useRouter();

  useEffect(() => {
    if (session?.token) {
      const path = onRedirectAuthentication(configuration?.features);
      router.push(path);
    } else {
      setLoading(false);
      router.push("/login");
    }
  }, []);

  return loading;
};

export default useCheckIsUserAuthenticated;
